<template>
	<b-modal
		ref="depuration-modal"
		:title="`Hembras para depurar del corral (${code_corral} / ${alias_corral})`"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="xmd"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<div class="d-flex pb-1">
				<b-badge variant="primary">
					<span>Total: {{ chicks.length }}</span>
				</b-badge>
			</div>
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header position-relative" style="z-index: 20">
					<b-th class="text-center"></b-th>
					<b-th class="text-center">Padrillo</b-th>
					<b-th class="text-center">Madrilla</b-th>
					<b-th class="text-center">Cintillo</b-th>
					<b-th class="text-center">Motivo</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(chick, index) in chicks" v-if="chicks.length > 0">
						<b-tr :key="index">
							<b-td>
								<div class="d-flex justify-content-center postion-relative" style="z-index: 1">
									<b-form-checkbox v-model="chick.is_check"></b-form-checkbox>
								</div>
							</b-td>
							<b-td>
								<SpecimenPlate :specimen="chick.padrillo" />
							</b-td>
							<b-td>
								<SpecimenPlate :specimen="chick.madrilla" />
							</b-td>
							<b-td class="text-center">
								<span>{{ chick.correlative }}</span>
							</b-td>
							<b-td>
								<b-form-textarea style="min-width: 12rem" no-resize v-model="chick.comentario" />
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="chicks.length == 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<div class="d-flex w-100 justify-content-end align-items-center gap-x-4" style="padding-inline: 1rem">
				<b-button @click="noDepuration" variant="warning" :disabled="getCountMod === 0">
					NO DEPURAR
					<b-badge v-if="getCountMod > 0" variant="danger">{{ getCountMod }}</b-badge>
				</b-button>

				<b-button @click="save" variant="primary" :disabled="getCountMod === 0">
					DEPURAR
					<b-badge v-if="getCountMod > 0" variant="danger">{{ getCountMod }}</b-badge>
				</b-button>
			</div>
		</b-overlay>

		<!-- <template #modal-footer>
			<div class="w-100 px-3 d-flex justify-content-between align-items-center">hello</div>
		</template> -->
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import ViewImage from "@/components/commons/ViewImage.vue"
import commonsService from "@/services/commons.service"
import reasonsService from "@/services/reasons.service"
import deadsService from "@/services/deads.service"
import axios from "@/axios"
import { mapActions } from "vuex"

export default {
	components: { SpecimenPlate, ViewImage },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
		corral_id: Number,
		alias_corral: String,
		code_corral: String,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			chicks: [],
			reasonOpts: [],
		}
	},
	async created() {
		await this.getChicks()
	},
	computed: {
		getCountMod() {
			return this.chicks.filter((it) => it.is_check).length
		},
	},
	methods: {
		...mapActions({
			A_GET_FEMALE_FOR_DEPURATION_COUNTER: "commons/A_GET_FEMALE_FOR_DEPURATION_COUNTER",
		}),
		async getOpts() {
			this.$emit("loading", true)
			const reasons = await reasonsService.getByModule()
			this.reasonOpts = reasons
			this.$emit("loading", false)
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		positionDropdown(dropdownList, component, { width, top, left }) {
			dropdownList.style.zIndex = 9999
			dropdownList.style.maxHeight = "20rem"
			dropdownList.style.top = top
			dropdownList.style.left = left
			dropdownList.style.width = width
		},
		async getChicks() {
			this.isPreloading()
			console.log(this.corral_id)

			try {
				const { data } = await commonsService.getFemaleChicksDetail(this.corral_id)
				this.chicks = data.map((it) => {
					return { ...it, is_check: false }
				})
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async noDepuration() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se registrara ${this.getCountMod} cintillo(s) en ala como NO DEPURADO`,
			})
			if (!isConfirmed) return

			const cintillos = []
			for (let i = 0; i < this.chicks.length; i++) {
				const row = this.chicks[i]
				if (row.is_check) {
					cintillos.push(row.id)
				}
			}
			await axios.post(`/api/chicks-female/send-to-no-depuration`, { chicks: cintillos })
			// await deadsService.insertDeadAla({ chicks: cintillos })
			await this.A_GET_FEMALE_FOR_DEPURATION_COUNTER()
			await this.getChicks()
			this.$emit("refresh")
			this.showToast("success", "top-right", "Muertos", "CheckIcon", "Guardado con exito")
			this.resetAll()
			this.$emit("loading", false)
		},

		async save() {
			const { isConfirmed } = await this.showConfirmSwal({
				title: `Se registrara ${this.getCountMod} cintillo(s) en ala como DEPURADO`,
			})
			if (!isConfirmed) return

			const cintillos = []
			for (let i = 0; i < this.chicks.length; i++) {
				const row = this.chicks[i]
				if (row.is_check) {
					cintillos.push({
						id: row.id,
						status_id: 4,
						reason_id: undefined,
						observation: row.comentario,
						images: [],
					})
				}
			}
			await deadsService.insertDeadAla({ chicks: cintillos })
			await this.A_GET_FEMALE_FOR_DEPURATION_COUNTER()
			await this.getChicks()
			this.$emit("refresh")
			this.showToast("success", "top-right", "Muertos", "CheckIcon", "Guardado con exito")
			this.resetAll()
			this.$emit("loading", false)
		},
	},
	watch: {},
	async mounted() {
		this.toggleModal("depuration-modal")
		this.getOpts()
	},
}
</script>

<style lang="scss" scoped></style>
