<template>
	<div class="h-100 d-flex align-items-center justify-content-center">
		<!-- <font-awesome-icon
			icon="fa-regular fa-clock"
			size="lg"
			
		/> -->
		<div @click="openModalCreate" class="mb-25">
			<v-icon
				name="la-user-clock-solid"
				scale="1.2"
				class="ml-50 cursor-pointer"
				:class="[modalCreateAttendance.date ? 'text-primary' : 'text-secondary']"
			/>
		</div>

		<b-modal
			@hide="hiddenModalCreate"
			size="xs"
			centered
			v-model="modalCreateAttendance.show"
			hide-footer
			hide-header
			body-class="m-0 p-0"
		>
			<div class="container-register">
				<div class="smile">
					<!-- LaHourglassStartSolid -->
					<!-- <FeatherIcon class="mr-25" icon="SmileIcon" color="#fff" size="30" /> -->
					<p class="text-uppercase mt-25">{{ modalCreateAttendance.text_attendance }}</p>
				</div>
				<div class="camera">
					<div class="container-camera">
						<CameraAttendance @setStream="setStream" v-show="!modalCreateAttendance.take" />
						<PictureAttendance @refresh="refresh" v-show="modalCreateAttendance.take" />
					</div>
					<!-- <img src="/rooster/rooster.jpg" alt="" /> -->
				</div>
				<div class="take-photo justify-content-center d-none d-md-flex" v-if="!modalCreateAttendance.take">
					<button class="btn btn-primary w-100" @click="takePicture">
						<v-icon name="fa-camera" scale="1.2" class="cursor-pointer" />
					</button>
				</div>
				<div class="clock">
					<v-icon name="hi-clock" scale="1.2" class="cursor-pointer text-primary" />
					<p class="">{{ modalCreateAttendance.hour }}</p>
				</div>

				<div class="send-photo">
					<button class="btn btn-primary" @click="attendanceInsert">Enviar</button>
				</div>

				<!-- <div class="body">
					<div class="box">
						<div class="container-box">
							<p>04:01:56</p>
						</div>
						<p>Entrada</p>
					</div>
					<div class="box box-info">
						<div class="container-box">
							<p>04:01:56</p>
						</div>
						<p>Salida</p>
					</div>
					<div class="time">
                        <p>7 <span>Horas</span></p>
                        <p>56 <span>Minutos</span></p>
                        <p>50 <span>Segundos</span></p>
                    </div>
				</div> -->
			</div>
			<div class="btn-container-attendance d-flex d-md-none">
				<div v-if="!modalCreateAttendance.take" class="circle" @click="takePicture">
					<div class="circle-2">
						<FeatherIcon class="text-primary" icon="CameraIcon" size="40" />
					</div>
				</div>
				<div v-else class="circle" @click="refresh">
					<div class="circle-2">
						<FeatherIcon class="text-primary" icon="RefreshCcwIcon" size="40" />
					</div>
				</div>
			</div>
		</b-modal>

		<b-toast id="toast-attendade-not-found" variant="primary" solid>
			<template #toast-title>
				<div class="d-flex flex-grow-1 align-items-baseline">
					<!-- <b-img blank blank-color="#fff" class="mr-1" width="8" height="8"></b-img> -->
					<strong class="mr-auto">Asistencia</strong>
					<!-- <small class="text-muted mr-2">42 seconds ago</small> -->
				</div>
			</template>
			<p class="mb-0">Ya se marcó las asistencias del día.</p>
			<p class="mb-0">
				Podria ver sus asistencias
				<span
					class="click-link"
					@click="
						modalViewAttendance.show = true
						$bvToast.hide('toast-attendade-not-found')
					"
				>
					Aqui
				</span>
				.
			</p>
			<!-- <b-link href="/asistencia">Link</b-link> -->
		</b-toast>

		<b-modal
			body-class="m-0 p-0"
			size="xl"
			v-model="modalViewAttendance.show"
			:title="modalViewAttendance.title"
			hide-footer
			centered
		>
			<AttendanceMain :is_local="1" />
		</b-modal>
	</div>
</template>

<script>
import CameraAttendance from "./CameraAttendance.vue"
import PictureAttendance from "./PictureAttendance.vue"
import attendanceService from "@/services/headers/attendance"
import AttendanceMain from "./views/AttendanceMain.vue"
import moment from "moment"

export default {
	name: "RegisterAttendance",
	components: {
		CameraAttendance,
		PictureAttendance,
		AttendanceMain,
	},
	data() {
		return {
			modalCreateAttendance: {
				show: false,
				take: false,
				image: null,
				hour: null,
				text_attendance: null,
				date: null,
			},
			modalViewAttendance: {
				show: false,
				title: "Asistencia",
			},
			stream: null,
		}
	},
	async mounted() {
		await this.attendanceCheck()
	},
	methods: {
		async openModalCreate() {
			await this.attendanceCheck()

			// await this.attendanceCheck();
			// this.modalCreateAttendance.show = true;
			if (this.modalCreateAttendance.date) {
				this.modalCreateAttendance.show = true
				this.mueveReloj(this.modalCreateAttendance.date)
			} else {
				//toast-attendade-not-found
				this.$bvToast.show("toast-attendade-not-found")
			}
		},
		takePicture() {
			let ratio = window.innerHeight < window.innerWidth ? 16 / 9 : 9 / 16
			const picture = document.querySelector("canvas")

			picture.width = 214
			picture.height = 214
			const ctx = picture.getContext("2d")
			ctx.imageSmoothingEnabled = true
			ctx.imageSmoothingQuality = "high"
			ctx.translate(214, 0)
			ctx.scale(-1, 1)
			ctx.drawImage(document.querySelector("video"), 0, 0, 214, 214)

			this.modalCreateAttendance.take = true

			const dataURL = picture.toDataURL()
			this.modalCreateAttendance.image = dataURL
		},
		refresh() {
			this.modalCreateAttendance.take = false
			// this.stopStream()
		},
		async attendanceInsert() {
			if (!this.modalCreateAttendance.image) {
				this.takePicture()
			}
			const { isConfirmed } = await this.showConfirmSwal()
			if (!isConfirmed) return

			this.isPreloading()

			const formData = new FormData()
			formData.append("image", this.modalCreateAttendance.image)
			// console.log("ADD", formData.get("image"));
			const { status } = await attendanceService.store(formData)
			if (status) {
				this.showToast(
					"success",
					"top-right",
					"Asistencia",
					"SuccessIcon",
					`Se registro su ${this.modalCreateAttendance.text_attendance} de forma correcta.`
				)
				this.modalCreateAttendance.show = false
				this.modalCreateAttendance.image = null
				this.modalCreateAttendance.hour = null
				this.modalCreateAttendance.text_attendance = null

				this.refresh()

				await this.attendanceCheck()
			}
			this.isPreloading(false)
		},
		async attendanceCheck() {
			if (!this.currentUser.id) return

			const { data } = await attendanceService.attendanceCheck()
			if (data.length > 0) {
				this.modalCreateAttendance.text_attendance = data[0].name
				this.modalCreateAttendance.date = data[0].current_date
			} else {
				this.modalCreateAttendance.date = null
				this.modalCreateAttendance.hour = null
			}
		},
		mueveReloj(date) {
			let momentoActual = new Date(date)
			let hora = momentoActual.getHours()
			let minuto = momentoActual.getMinutes()
			let segundo = momentoActual.getSeconds()

			let str_segundo = new String(segundo)
			if (str_segundo.length == 1) segundo = "0" + segundo

			let str_minuto = new String(minuto)
			if (str_minuto.length == 1) minuto = "0" + minuto

			let str_hora = new String(hora)
			if (str_hora.length == 1) hora = "0" + hora

			let horaImprimible = hora + " : " + minuto + " : " + segundo
			this.modalCreateAttendance.hour = horaImprimible

			setTimeout(() => {
				this.modalCreateAttendance.hour && this.mueveReloj(moment(momentoActual).add(1, "second"))
			}, 1000)
		},
		stopStream() {
			const tracks = this.stream.getTracks()

			// tracks[0].stop()

			tracks.forEach((track) => track.stop())

			this.stream = null
		},
		setStream(stream) {
			this.stream = stream
		},
		hiddenModalCreate() {
			this.refresh()
			this.stopStream()
			this.modalCreateAttendance.hour = null
			// this.modalCreateAttendance.date = null;
		},
	},
}
</script>

<style scoped lang="scss">
.container-register {
	// padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: hidden;
	position: relative;
	background: rgba(115, 103, 240, 0.1);
	.smile {
		border-top-left-radius: 0.358rem;
		border-top-right-radius: 0.358rem;
		width: 100%;
		background: #7367f0;
		padding: 0.8rem 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		p {
			margin-bottom: 0;
			color: #fff;
			font-size: 16px;
			font-weight: bolder;
		}
		margin-bottom: 2rem;
	}

	.take-photo {
		position: absolute;
		bottom: 150px;
		button {
			padding: 0.4rem 4rem;
			border-radius: 20px;
		}
	}

	.send-photo {
		button {
			padding: 0.6rem 4rem;
			border-radius: 20px;
			font-size: 16px;
		}
		margin-bottom: 2rem;
	}

	.camera {
		// margin-top: 2rem;
		// position: relative;
		text-align: center;

		.container-camera {
			overflow: hidden;
			// background: #f5f5f5;
			// position: absolute;
			// bottom: 0;
			// left: 50%;
			// width: 100%;
			width: 238px;
			height: 238px;
			// transform: translateX(-50%);
			border-radius: 12px;
			border: 6px solid #8900ee;
			padding: 6px;
		}
		img {
		}
	}
	.header {
		margin-top: 1rem;
		border-radius: 12px;
		padding: 1rem;
		border: 2px solid #f8f9fa;
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			font-size: 16px;
			font-weight: bolder;
			margin-bottom: 0;
		}
		button {
			// padding: 0.7rem 3rem;
			padding: 0.5rem 0.6rem;
		}
	}
	.clock {
		display: flex;
		border: 2px solid #7367f0;
		padding: 0.5rem 2rem;
		border-radius: 20px;
		margin-top: 1rem;
		margin-bottom: 1rem;
		background: #fff;
		p {
			margin-bottom: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 0.4rem;
			font-size: 16px;
			font-weight: bolder;
			color: rgba(88, 88, 88, 1);
		}
	}
	.body {
		padding: 1rem;
		display: grid;
		gap: 1rem;
		margin-top: 1rem;
		grid-template-columns: 1fr 1fr;
		background: #f8f9fa;
		.box {
			.container-box {
				background: #00f8b8;
				height: 80px;
				width: 80px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				border-radius: 50%;
				p {
					font-size: 16px;
					margin-top: 0.5rem;
					color: #fff;
					font-weight: bolder;
				}
			}
			border-radius: 20px;
			padding: 1rem;
			background: #d3fced;
			p {
				margin-bottom: 0;
				text-align: center;
				margin-top: 0.5rem;
				color: #000;
				font-weight: bolder;
			}
		}
		@keyframes info-bg {
			0% {
				background-color: #00dbf8;
				transform: scale(1);
			}
			50% {
				background-color: #19dff9;
				transform: scale(1.02);
				box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
			}
			100% {
				background-color: #00dbf8;
				transform: scale(1);
			}
			// 100% {
			// 	background-color: #19dff9;
			//     transform: scale(1.02);
			//     box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
			// }
		}
		.box-info {
			background: #b3fafa !important;
			.container-box {
				background: #00dbf8;
				animation: info-bg 1s infinite;
			}
		}

		.time {
			display: flex;
			align-items: flex-start;
			justify-content: center;
			flex-direction: column;
			p {
				margin-bottom: 0;
				margin: 0.3rem 0;
				font-weight: bolder;
				span {
					font-weight: normal;
				}
			}
		}
	}
}
.btn-container-attendance {
	background: #f3ecfd;
	position: absolute;
	bottom: -180px;
	left: 50%;
	width: 80px;
	height: 80px;
	transform: translateX(-50%);

	border-radius: 50%;
	border: 1px solid #818181;
	transition: 0.3s all ease-in-out;
	&:hover {
		background: #f5f5f5;
	}
	.circle {
		width: 100%;
		height: 100%;
		border: 1px solid #fff;
		border-radius: 50%;
		.circle-2 {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 1px solid #818181;
			cursor: pointer;
		}
	}
}
.click-link {
	color: #8900ee;
	cursor: pointer;
}
</style>
