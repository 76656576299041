<template>
	<b-modal
		ref="depuration-modal"
		:title="'Corrales con hembras para depurar'"
		centered
		v-model="isActive"
		@hidden="handleHidden"
		size="lg"
		hide-footer
	>
		<b-overlay :show="isLoading">
			<b-table-simple responsive sticky-header="60vh" no-border-collapse>
				<b-thead class="sticky-header">
					<b-th class="text-center">Codigo</b-th>
					<b-th class="text-center">Nombre</b-th>
					<b-th class="text-center">Hembras</b-th>
					<b-th class="text-center">Ultimo registro</b-th>
				</b-thead>
				<b-tbody>
					<template v-for="(chick, index) in chicks" v-if="chicks.length > 0">
						<b-tr :key="index">
							<b-td class="text-center">
								<span>{{ chick.code }}</span>
							</b-td>
							<b-td class="text-center">
								<span>{{ chick.alias }}</span>
							</b-td>
							<b-td class="text-center">
								<span
									class="cursor-pointer text-primary"
									style="font-weight: bold"
									@click="
										corral_id = chick.id
										showDepuration = true
										alias_corral = chick.alias
										code_corral = chick.code
									"
									v-if="chick.counter"
								>
									{{ chick.counter }}
								</span>
								<span v-else>---</span>
							</b-td>
							<b-td>
								<div class="d-flex flex-column justify-content-center w-100 align-items-center">
									<span>{{ chick.last_register.user }}</span>
									<span>{{ chick.last_register.date | myGlobalDateTime }}</span>
								</div>
							</b-td>
						</b-tr>
					</template>
					<b-tr v-if="chicks.length == 0">
						<b-td colspan="10" class="text-center" style="height: 80px">No hay registros para mostrar</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
		</b-overlay>

		<DepurationFemaleModal
			v-if="showDepuration"
			:corral_id="corral_id"
			:alias_corral="alias_corral"
			:code_corral="code_corral"
			@closing="showDepuration = false"
			@refresh="getChicks"
		/>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import ViewImage from "@/components/commons/ViewImage.vue"
import commonsService from "@/services/commons.service"
import DepurationFemaleModal from "@/views/amg/header-bookmarks/DepurationFemaleModal.vue"

export default {
	components: { ViewImage, DepurationFemaleModal },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			chicks: [],
			showDepuration: false,
			corral_id: null,
			alias_corral: null,
			code_corral: null,
		}
	},
	async created() {
		await this.getChicks()
	},
	computed: {},
	methods: {
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},

		async getChicks() {
			this.isPreloading()

			try {
				const { data } = await commonsService.getFemaleChicks()
				this.chicks = data
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {},
	async mounted() {
		this.toggleModal("depuration-modal")
	},
}
</script>

<style lang="scss" scoped></style>
