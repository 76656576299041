<template>
	<div>
		<b-modal
			ref="modal-spences-by-petty-cash"
			@hidden="$emit('close')"
			:title="getTitle"
			cancel-title="Cancelar"
			scrollable
			size="xmd"
		>
			<div>
				<div>
					<b-row>
						<b-col sm="12" md="12" lg="4">
							<b-form-group>
								<label for="petty_cash_id">
									Caja chica
									<span class="text-danger">({{ pettyCash.length }})</span>
								</label>
								<v-select
									input-id="petty_cash_id"
									:clearable="false"
									v-model="pettyCashId"
									label="code"
									:options="pettyCash"
									:reduce="(cc) => cc.id"
									@input="changePettyCash"
								>
									<template v-slot:option="option">
										<div class="d-flex justify-content-between">
											<span :class="{ 'text-danger': option.balance <= option.balance_min }">
												{{ option.code }}
											</span>
											<span v-if="option.balance <= option.balance_min" class="mr-2 text-danger">
												( {{ option.balance | currency }} )
											</span>
										</div>
									</template>
									<template #selected-option="{ balance, code, balance_min }">
										<div class="d-flex justify-content-between">
											<span :class="{ 'text-danger': balance <= balance_min }">
												{{ code }}
											</span>
											<!-- <span v-if="balance <= balance_min" class="mr-2 text-danger">
												( {{ balance }} )
											</span> -->
										</div>
									</template>
								</v-select>
							</b-form-group>
						</b-col>
						<b-col md="6" lg="4">
							<b-form-group>
								<label>Fecha</label>
								<div
									v-if="date != null"
									class="d-flex align-items-center border rounded pl-1"
									style="height: 35px"
								>
									{{ date | myGlobalDayShort }}
								</div>
								<div v-else class="d-flex align-items-center border rounded pl-1" style="height: 35px">
									-
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<b-form-group>
								<label>Responsable</label>
								<div class="d-flex align-items-center border rounded pl-1" style="height: 35px">
									{{ responsible }}
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6" lg="4">
							<b-form-group>
								<label class="text-success">Deposito</label>
								<div
									class="d-flex align-items-center border-success rounded pl-1 text-success"
									style="height: 35px"
								>
									{{ getAmount | currency }}
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<b-form-group>
								<label class="text-danger">Gastos</label>
								<div
									class="d-flex align-items-center border-danger rounded pl-1 text-danger"
									style="height: 35px"
								>
									{{ getExpenses | currency }}
								</div>
							</b-form-group>
						</b-col>

						<b-col md="6" lg="4">
							<b-form-group>
								<label class="text-primary">Saldo</label>
								<div
									class="d-flex align-items-center border-primary rounded pl-1 text-primary"
									style="height: 35px"
								>
									{{ (getAmount - getExpenses) | currency }}
								</div>
							</b-form-group>
						</b-col>
					</b-row>
					<div v-if="pettyCashId != null" class="d-flex justify-content-end align-items-center p-1">
						<b-button
							class="btn-sm ml-1 d-flex align-items-center"
							variant="warning"
							@click="addCashInput('output')"
						>
							Agregar gasto
						</b-button>
					</div>
				</div>

				<b-row class="mt-1 mb-1">
					<b-col class="d-flex align-items-center">
						<small class="text-secondary">
							Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
						</small>
					</b-col>
					<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
						<b-pagination
							v-model="pagination.curPage"
							:total-rows="pagination.total"
							:per-page="pagination.perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col cols="12" xl="3" class="d-flex align-items-center justify-content-start mb-1 mb-xl-0">
						<label>Mostrar</label>
						<v-select
							v-model="pagination.perPage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							style="width: 90px"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
					</b-col>
					<b-col
						cols="12"
						xl="5"
						class="d-flex flex-column flex-xl-row align-items-xl-center justify-content-xl-center gap-x-2 mb-1 mb-xl-0"
					>
						<div class="d-flex align-items-center">
							<b-form-datepicker
								class="border-primary"
								style="width: 150px"
								locale="en-US"
								placeholder="Desde..."
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="weekFrom"
							/>
							<b-form-datepicker
								class="border-primary ml-1"
								locale="en-US"
								style="width: 150px"
								placeholder="Hasta..."
								:date-format-options="{
									month: 'numeric',
									day: 'numeric',
									year: 'numeric',
								}"
								v-model="weekTo"
							/>

							<span @click="cleanFilters()" class="ml-1 cursor-pointer">
								<v-icon name="gi-broom"></v-icon>
								Limpiar
							</span>
						</div>
					</b-col>
					<b-col cols="12" xl="4">
						<div class="container-btns-page-specimens">
							<div class="d-flex w-100">
								<b-form-input
									v-model="search"
									class="d-inline-block mr-1"
									placeholder="Buscar..."
									@keyup.enter="changePettyCash"
									debounce="500"
								/>
							</div>
						</div>
					</b-col>
				</b-row>

				<b-table-simple responsive sticky-header="50vh" no-border-collapse>
					<b-thead class="sticky-header">
						<b-th>Descripcion</b-th>
						<b-th>Sustento</b-th>
						<b-th class="text-center">Monto</b-th>
						<b-th>Observacion</b-th>
						<b-th class="text-center">Creado por</b-th>
						<b-th class="text-center">Acciones</b-th>
					</b-thead>
					<b-tbody>
						<template v-for="transac in transactions">
							<b-tr>
								<b-td>
									{{ transac.description ? transac.description : "-" }}
								</b-td>
								<b-td>
									<div v-if="transac.url != null" class="d-flex">
										<b-avatar
											class="text-dark w-100"
											icon="image"
											square
											rounded="lg"
											variant="light"
											size="4rem"
											:src="transac.url"
										/>
										<div class="d-flex flex-column justify-content-center" style="margin-left: 5px">
											<a ref="downloadLink" :href="transac.url" download target="_blank">
												<feather-icon
													icon="DownloadIcon"
													class="text-danger cursor-pointer"
													size="17"
												/>
											</a>
										</div>
									</div>
								</b-td>
								<b-td>
									<div class="d-flex justify-content-center">
										<span class="text-danger">{{ transac.amount | currency }}</span>
									</div>
								</b-td>
								<b-td>
									{{ transac.observation ? transac.observation : "-" }}
								</b-td>
								<b-td>
									<div class="d-flex flex-column align-items-center justify-content-center">
										<span>{{ transac.created_by }}</span>
										<span style="font-size: 13px">
											<feather-icon icon="CalendarIcon" class="text-success" />
											{{ transac.created_at | myGlobalDayWithHour }}
										</span>
									</div>
								</b-td>
								<b-td>
									<div class="d-flex align-items-center justify-content-center">
										<b-button
											variant="danger"
											class="btn-icon btn-sm"
											@click="deleteTransaction(transac)"
										>
											<feather-icon icon="Trash2Icon" />
										</b-button>
									</div>
								</b-td>
							</b-tr>
						</template>
					</b-tbody>
				</b-table-simple>
			</div>
			<template #modal-footer>
				<b-button variant="danger" @click="$emit('close')">Cerrar</b-button>
			</template>
		</b-modal>

		<CashInputModal
			v-if="cashInputModal"
			:pettyCash="modal.pettyCash"
			:typeTransaction="typeTransaction"
			@close="cashInputModal = false"
			@registered="resetSearch(), (cashInputModal = false)"
		/>
	</div>
</template>

<script>
import Ripple from "vue-ripple-directive"
import modalMixin from "@/mixins/modal.js"
import PettyCashService from "@/views/amg/management/views/petty-cash/services/petty-cash.service.js"
import CashInputModal from "@/views/amg/management/views/petty-cash/components/CashInputModal.vue"

export default {
	props: {},
	directives: { Ripple },
	components: { CashInputModal },
	mixins: [modalMixin],
	data() {
		return {
			pettyCash: [],
			transactions: [],
			inputs: [],
			pettyCashId: null,
			code: null,
			date: null,
			responsible: null,
			cashInputModal: false,
			modal: {
				pettyCash: {},
			},
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 50,
				from: 0,
				to: 0,
			},
			weekFrom: null,
			weekTo: null,
			search: null,
		}
	},
	computed: {
		getTitle() {
			return "Caja chica"
		},
		getAmount() {
			const filtered = this.inputs.find((i) => i.type === "input")

			if (!filtered) return 0
			return filtered.amount
		},
		getExpenses() {
			const filtered = this.inputs.find((i) => i.type === "output")

			if (!filtered) return 0
			return filtered.amount
		},
	},
	async mounted() {
		this.toggleModal("modal-spences-by-petty-cash")
	},
	async created() {
		await this.getMyPettyCash()
	},
	methods: {
		cleanFilters() {
			this.weekFrom = null
			this.weekTo = null
		},
		async refresh() {
			await this.getMyPettyCash()
		},
		async getMyPettyCash() {
			this.isPreloading()
			try {
				const params = {
					isAdmin: this.isAdmin ? 1 : 0,
				}
				const { data } = await PettyCashService.getPettyCashByUserId(params)

				this.pettyCash = data

				if (this.pettyCash.length) {
					this.pettyCashId = this.pettyCash[0].id
					await this.changePettyCash(false)
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		async resetSearch() {
			await this.changePettyCash()
		},

		async changePettyCash(isPreload = true) {
			if (isPreload) {
				this.isPreloading()
			}
			try {
				const { data } = await PettyCashService.getDataPettyCashById({
					id: this.pettyCashId,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
					start_date: this.weekFrom ? this.weekFrom : null,
					end_date: this.weekTo ? this.weekTo : null,
					type: "output",
					search: this.search,
				})
				console.log(data)
				this.date = data.petty_cash.date
				this.responsible = data.petty_cash.responsible
				this.code = data.petty_cash.code
				const transactions = data.transactions.data
				this.inputs = data.petty_cash.totals
				this.transactions = transactions.filter((t) => t.type == "output")
				this.pagination.total = data.transactions.total
				this.pagination.from = data.transactions.from
				this.pagination.to = data.transactions.to
			} catch (error) {
				console.log(error)
			} finally {
				if (isPreload) {
					this.isPreloading(false)
				}
			}
		},

		async deleteTransaction(transac) {
			const confirm = await this.showConfirmSwal({
				text: "Eliminar transaccion",
			})
			if (!confirm.isConfirmed) return
			this.isPreloading()
			try {
				const deleted = await PettyCashService.deleteTransaction(transac.id)
				this.showSuccessToast("Transaccion eliminado correctamente")
				this.resetSearch()
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},

		addCashInput(type = "input") {
			this.typeTransaction = type
			this.cashInputModal = true
			this.modal.pettyCash = {
				id: this.pettyCashId,
				code: this.code,
				date: this.date,
				amount: this.getAmount,
				expenses: this.getExpenses,
				responsible: this.responsible,
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.changePettyCash()
		},
		"pagination.perPage": async function () {
			await this.changePettyCash()
		},
		weekFrom: async function () {
			await this.changePettyCash()
		},
		weekTo: async function () {
			await this.changePettyCash()
		},
	},
}
</script>
