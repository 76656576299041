<template>
	<b-nav-item-dropdown
		right
		toggle-class="d-flex align-items-center dropdown-user-link"
		class="dropdown-user"
		v-if="currentUser"
	>
		<!-- <pre>{{ currentUser }}</pre> -->
		<template #button-content>
			<div class="d-sm-flex d-none user-nav">
				<p class="user-name font-weight-bolder mb-0">{{ currentUser.name }} {{ currentUser.last_name }}</p>
				<span class="user-status" v-if="currentUser.roles && currentUser.roles.length > 0">
					{{ currentUser.roles[0].name }}
				</span>
			</div>
			<b-avatar
				size="40"
				:src="currentUser.avatar"
				variant="light-primary"
				badge
				class="badge-minimal"
				badge-variant="success"
			>
				<feather-icon v-if="!currentUser.avatar" icon="UserIcon" size="22" />
			</b-avatar>
		</template>

		<b-dropdown-item
			:to="{ name: 'editar-user', params: { user_id: currentUser.user_id } }"
			link-class="d-flex align-items-center"
		>
			<feather-icon size="16" icon="UserIcon" class="mr-50" />
			<span>Perfil</span>
		</b-dropdown-item>

		<b-dropdown-item
			v-if="validAttendande"
			@click="modalViewAttendance.show = true"
			link-class="d-flex align-items-center"
		>
			<feather-icon size="16" icon="ClockIcon" class="mr-50" />
			<span>Asistencia</span>
		</b-dropdown-item>

		<b-modal
			body-class="m-0 p-0"
			size="xl"
			v-model="modalViewAttendance.show"
			:title="modalViewAttendance.title"
			hide-footer
		>
			<AttendanceMain :is_local="1" />
		</b-modal>
		<b-dropdown-item link-class="d-flex align-items-center" @click="doLogout">
			<feather-icon size="16" icon="LogOutIcon" class="mr-50" />
			<span>Cerrar sesión</span>
		</b-dropdown-item>
	</b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue"
import { mapActions } from "vuex"
// import { initialAbility } from "@/libs/acl/config";
import { avatarText } from "@core/utils/filter"
import AttendanceMain from "@/views/amg/header-bookmarks/attendance/views/AttendanceMain.vue"

export default {
	components: {
		BNavItemDropdown,
		BDropdownItem,
		BDropdownDivider,
		BAvatar,
		AttendanceMain,
	},
	data() {
		return {
			avatarText,
			// notifications: [],
			// results: [],
			modalViewAttendance: {
				show: false,
				title: "Asistencia",
			},
		}
	},
	computed: {
		validAttendande() {
			let valid = false
			const rolesArray = [2, 3, 4, 5, 6, 8, 9, 10, 11]
			const rolesUser = this.roles
			for (let index = 0; index < rolesArray.length; index++) {
				const element = rolesArray[index]
				for (let indexUser = 0; indexUser < rolesUser.length; indexUser++) {
					const element = rolesUser[indexUser]
					if (rolesUser[indexUser] == rolesArray[index]) {
						valid = true
					}
				}
			}
			return valid
		},
	},
	methods: {
		...mapActions("auth", ["logout"]),
		doLogout() {
			this.logout()
		},
	},
}
</script>

<style lang="scss">
.user-nav {
	flex-wrap: nowrap !important;
}
</style>
