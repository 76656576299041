<template>
	<b-modal
		ref="modal-days-without-topes"
		:title="'Ejemplares sin topar'"
		no-close-on-backdrop
		centered
		v-model="isActive"
		@hidden="handleHidden"
		:size="indexTab === 0 ? 'xmd' : 'xlg'"
		hide-footer
	>
		<div>
			<div v-if="G_DAYS_WITHOUT_TOPES_COUNTER.length > 0">
				<b-row>
					<b-col class="d-flex align-items-center" cols="12" md="12">
						<b-tabs v-model="indexTab" class="w-full">
							<b-tab
								:disabled="false"
								v-for="(status, index) in G_DAYS_WITHOUT_TOPES_COUNTER"
								@click="tabSelected = status.id"
								:key="index"
							>
								<template #title>
									<span>{{ status.name }}</span>
									<b-badge variant="primary" class="ml-1">{{ status.counter }}</b-badge>
								</template>
							</b-tab>
						</b-tabs>
					</b-col>
				</b-row>

				<b-row class="mb-1">
					<b-col class="d-flex align-items-center">
						<small class="text-secondary">
							Mostrando {{ pagination.from }} a {{ pagination.to }} de {{ pagination.total }} registros
						</small>
					</b-col>
					<b-col class="d-flex align-items-center justify-content-end flex-wrap gap-x-4 gap-y-1">
						<b-pagination
							v-model="pagination.curPage"
							:total-rows="pagination.total"
							:per-page="pagination.perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
				<b-row class="mb-1">
					<b-col cols="12" xl="7" class="d-flex align-items-center justify-content-start mt-1 mb-md-0">
						<label>Mostrar</label>
						<v-select
							v-model="pagination.perPage"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
							style="width: 90px"
						/>
						<label class="mr-2">registros</label>
						<feather-icon class="cursor-pointer" icon="RefreshCcwIcon" size="20" @click="refresh" />
					</b-col>
					<b-col cols="12" xl="2">
						<b-form-group label-class="label-filter" label="CATEGORIA">
							<v-select
								v-model="category_id"
								:reduce="(it) => it.id"
								label="description"
								:options="categoryOpts"
								placeholder="Seleccionar..."
							/>
						</b-form-group>
					</b-col>
					<!-- Search -->
					<b-col cols="12" xl="3" style="margin-top: 1.6rem">
						<div class="container-btns-page-specimens">
							<div class="d-flex">
								<b-form-input
									v-model="search_input"
									class="d-inline-block mr-1"
									placeholder="Placa o alias..."
									@keyup.enter="getSpecimens"
									debounce="500"
								/>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-table-simple responsive sticky-header="60vh" no-border-collapse>
					<b-thead class="sticky-header" style="white-space: nowrap">
						<b-th class="text-center">Ejemplar</b-th>
						<b-th class="text-center">Estado</b-th>
						<b-th class="text-center">Categoria</b-th>
						<b-th class="text-center" v-if="[0,1].includes(indexTab)">Peso</b-th>
						<b-th class="text-center" v-if="indexTab === 1">Edad</b-th>
						<b-th class="text-center">Ultimo tope</b-th>
						<b-th class="text-center !cursor-pointer" style="cursor: pointer" @click="toggleOrderBy">
							<span>Dias sin topar</span>
							<font-awesome-icon
								icon="fa-solid fa-arrow-up-long"
								size="lg"
								class="text-text ml-1 cursor-pointer"
								:class="{ 'text-primary': orderBy === 'ASC' }"
							/>
							<font-awesome-icon
								icon="fa-solid fa-arrow-down-long"
								size="lg"
								class="text-text cursor-pointer"
								:class="{ 'text-primary': orderBy === 'DESC' }"
							/>
						</b-th>
					</b-thead>
					<b-tbody>
						<template v-if="specimens.length > 0" v-for="(specimen, index) in specimens">
							<b-tr :key="index">
								<b-td class="text-center">
									<SpecimenPlate :specimen="specimen.specimen" />
								</b-td>
								<b-td>
									<div style="display: flex; flex-direction: column; align-items: center">
										<div class="d-flex align-items-center justify-content-center gap-x-1">
											<feather-icon
												size="18"
												v-b-tooltip.hover.top="
													specimen.specimen.in_galpon === 1 ? 'Disponible' : 'No disponible'
												"
												:class="{
													'text-danger': specimen.specimen.in_galpon === 0,
													'text-success': specimen.specimen.in_galpon === 1,
												}"
												icon="CheckCircleIcon"
											></feather-icon>
											<span class="text-center">{{ specimen.specimen.status }}</span>
										</div>
										<span
											v-if="tabSelected === 10 && specimen.training_status"
											class="text-primary"
										>
											({{ specimen.training_status }})
										</span>
									</div>
								</b-td>
								<b-td class="text-center">
									<span>{{ specimen.category.name }}</span>
								</b-td>
								<b-td class="text-center" v-if="[0,1].includes(indexTab)">
									<p v-if="specimen.kg_weight">
										<span class="font-weight-bolder">{{ specimen.kg_weight }}</span>
										Kg
										<br />
										<span class="font-weight-bolder">{{ specimen.pounds_weight }}</span>
										Lb
									</p>
									<p v-else>---</p>
								</b-td>
								<b-td class="text-center" v-if="indexTab === 1">
									<SpecimenAge :age="specimen.specimen.dob" v-if="specimen.specimen.dob" />
									<span v-else>---</span>
								</b-td>
								<b-td class="text-center">
									<span v-if="specimen.quantity_tope === 0">---</span>
									<span v-else>
										{{ specimen.last_date_tope | myGlobalDayShort }}
									</span>
								</b-td>
								<b-td class="text-center">
									<b-badge variant="light-danger" v-if="specimen.quantity_tope === 0">
										Nunca topó
									</b-badge>
									<span v-else class="text-primary">{{ specimen.diff_days }}</span>
								</b-td>
							</b-tr>
						</template>
						<b-tr v-if="specimens.length === 0">
							<b-td colspan="10" class="text-center" style="height: 80px">
								No hay registros para mostrar
							</b-td>
						</b-tr>
					</b-tbody>
				</b-table-simple>
			</div>
			<div v-else>
				<div
					class="text-center d-flex align-items-center justify-content-center"
					style="min-height: 30vh; box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
				>
					<p style="font-size: 1.5rem">
						Es necesario configurar los dias sin topar en la seccion de estados.
					</p>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import SpecimenService from "@/services/specimens.service.js"
import { orderBy } from "lodash"
import { mapState, mapMutations, mapActions, mapGetters } from "vuex"
import SpecimenAge from "@/components/specimens/SpecimenAge.vue"

export default {
	components: { SpecimenPlate, SpecimenAge },
	mixins: [modalMixin],
	props: {
		show: Boolean,
		info: Object,
	},
	data() {
		return {
			isActive: false,
			isLoading: false,
			specimens: [],
			perPageOptions: [10, 25, 50, 100],
			pagination: {
				total: 0,
				curPage: 1,
				perPage: 50,
				from: 0,
				to: 0,
			},
			search_input: "",
			orderBy: "DESC",
			category_id: null,
			status_id: null,
			indexTab: 0,
			indexSubTab: 0,
			tabSelected: null,
		}
	},
	async created() {
		await this.A_GET_DAYS_WITHOUT_TOPES_COUNTER()
		if (this.G_DAYS_WITHOUT_TOPES_COUNTER.length >= 1) {
			this.tabSelected = this.G_DAYS_WITHOUT_TOPES_COUNTER[0].id
		}
	},
	computed: {
		...mapState("createEditSpecimen", ["S_OPTIONS_MACHO"]),
		...mapGetters({
			G_DAYS_WITHOUT_TOPES_COUNTER: "commons/G_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		categoryOpts() {
			return this.S_OPTIONS_MACHO
		},
	},
	methods: {
		...mapActions({
			A_GET_DAYS_WITHOUT_TOPES_COUNTER: "commons/A_GET_DAYS_WITHOUT_TOPES_COUNTER",
		}),
		async refresh() {
			await this.getSpecimens()
		},
		handleHidden() {
			this.isActive = false
			this.isLoading = false
			this.$emit("closing")
		},
		toggleOrderBy() {
			if (this.orderBy === "DESC") {
				this.orderBy = "ASC"
			} else {
				this.orderBy = "DESC"
			}
		},
		async getSpecimens() {
			this.isPreloading()
			try {
				const { data } = await SpecimenService.getSpecimensWithDaysWithoutTopes({
					search: this.search_input,
					page: this.pagination.curPage,
					perpage: this.pagination.perPage,
					order: null,
					orderBy: this.orderBy,
					status: this.G_DAYS_WITHOUT_TOPES_COUNTER.length === 0 ? null : this.tabSelected,
					category: this.category_id,
				})
				this.specimens = data.data
				this.pagination.total = data.total
				this.pagination.from = data.from
				this.pagination.to = data.to
			} catch (error) {
				console.log(error)
			} finally {
				this.isPreloading(false)
			}
		},
	},
	watch: {
		"pagination.curPage": async function () {
			await this.getSpecimens()
		},
		"pagination.perPage": async function () {
			await this.getSpecimens()
		},
		category_id: async function () {
			this.pagination.curPage = 1
			await this.getSpecimens()
		},
		tabSelected: async function () {
			this.pagination.curPage = 1
			this.category_id = null
			await this.getSpecimens()
		},
		orderBy: async function () {
			this.pagination.curPage = 1
			await this.getSpecimens()
		},
	},
	async mounted() {
		this.toggleModal("modal-days-without-topes")
	},
}
</script>

<style>
.label-filter {
	font-size: 0.8rem;
}

.nav.nav-tabs {
	flex-wrap: nowrap;
	overflow-x: auto;
	padding-bottom: 0.5rem;
}

.nav-link {
	white-space: nowrap;
}
</style>
